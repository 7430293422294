import { GET, POST, PUT, DELETE } from '../utils/request';
export function sendGetADemoRequestToSF(data, requiredFields = null) {
  return POST(
    `/api/get-a-demo`,
    {
      ...data,
      requiredFields, // 如果 requiredFields 为 null，API 会使用默认必填字段
    },
    {
      noNeedLogin: true,
    }
  );
}

export function sendReferralRequestToSF(data) {
  return POST(`/api/referral`, data, {
    noNeedLogin: true,
  });
}
